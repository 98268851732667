import { Link } from '@remix-run/react';
import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';
import type { ActivitiesQuery, PublicActivitiesQuery } from '~/generated/server';
import { useTranslation } from '~/i18n';
import { cn } from '~/utils/cn';
import type { ConnectionResultToNodeArray } from '~/utils/connection-result-to-node-array';
import { convertToStars, formatPrice } from '~/utils/number';
import { Coin } from './Coin';
import { LocationPin } from './icons/LocationPin';
import { Star } from './icons/Star';
import { buttonVariants } from './ui/Button';
import { Image } from './ui/Image';
import { Skeleton } from './ui/Skeleton';
import { type H4, HeadingM, HeadingS, HeadingXS, textVariants } from './ui/Typography';

interface Props extends Omit<React.ComponentProps<typeof Link>, 'to'> {
  to?: string;
  activity:
    | NonNullable<ConnectionResultToNodeArray<PublicActivitiesQuery['publicActivities']>>[number]
    | NonNullable<ConnectionResultToNodeArray<ActivitiesQuery['activities']>>[number];
  canRegister?: boolean;
  imgLoading?: 'eager' | 'lazy';
  fetchpriority?: 'auto' | 'high' | 'low';
  decoding?: 'async' | 'sync' | undefined;
}

export function ActivityCard({
  activity,
  canRegister,
  imgLoading = 'lazy',
  fetchpriority = undefined,
  decoding,
  ...props
}: Props) {
  const { t } = useTranslation();

  const isDiscounted =
    'viewerPrice' in activity && activity.viewerPrice.amount !== activity.price.amount;

  return (
    <Link
      prefetch="intent"
      to={`/activites-senior/${activity.slug || activity.id}`}
      {...props}
      className={cn(
        'relative flex flex-col overflow-hidden rounded-lg bg-card shadow-[0px_4px_0px_0px_rgba(25,_48,_53,_0.04)]',
        props.className,
      )}
    >
      <Image
        alt={activity.name}
        src={activity.picture?.url || '/images/placeholder.png'}
        className="h-[220px] w-full rounded-t-lg object-cover object-center"
        height={220}
        width={400}
        loading={imgLoading}
        {...(fetchpriority ? { priority: fetchpriority } : {})}
        sizes="(min-width: 300px) 300px, 420px"
        decoding={decoding}
      />
      <div className="flex flex-1 flex-col gap-y-4 p-4">
        <HeadingXS className="line-clamp-2" asChild>
          <h3>{activity.name}</h3>
        </HeadingXS>
        <div
          className={cn(
            textVariants({
              size: 'm',
              className: 'font-medium text-subdued-foreground',
            }),
            { 'space-y-2': activity.scene === 'OFFLINE' },
          )}
        >
          <p>
            {'date' in activity
              ? dayjs(activity.date).format('DD MMMM')
              : capitalize(dayjs(activity.startTime).calendar())}
            &nbsp; &#x2022; &nbsp;
            {dayjs.duration(activity.duration, 'minute').format('H[h]mm')}
            {/* &nbsp; &#x2022; &nbsp;
            {t('activity-attendee-count', { count: activity.attendeeCount })} */}
          </p>
          {activity.scene === 'OFFLINE' ? (
            <span className="flex items-start gap-1.5">
              <LocationPin className="size-5 min-w-5" />
              <span className="line-clamp-1">
                {activity.areas.map((area) => area?.displayName).join(', ')}
              </span>
            </span>
          ) : null}
        </div>

        <div className="mt-auto flex flex-wrap items-center gap-1">
          {activity.attendeeCount === activity.maxAttendees ? (
            <ActivityBadge className="border-surface-red bg-surface-red">{t('full')}</ActivityBadge>
          ) : null}

          {activity.scene === 'ONLINE' ? (
            <ActivityBadge className="border-quaternary bg-quaternary">{t('online')}</ActivityBadge>
          ) : null}

          {activity.price.amount ? (
            <ActivityBadge className="px-2 font-bold">
              {activity.price.currency === 'COL' ? (
                <>
                  <Coin className="size-4" />
                  <span className="block px-1">1</span>
                </>
              ) : (
                <span>
                  <span
                    className={cn({
                      'line-through decoration-2': isDiscounted,
                    })}
                  >
                    {formatPrice(activity.price.amount, {
                      currency: activity.price.currency,
                    })}
                  </span>
                  {isDiscounted ? (
                    <span className="text-[#fb9800] ml-2">
                      {formatPrice(activity.viewerPrice.amount, {
                        currency: activity.viewerPrice.currency,
                        maximumFractionDigits: 0,
                      })}
                    </span>
                  ) : null}
                </span>
              )}
            </ActivityBadge>
          ) : null}

          {/* {activity.categories?.map((category) => (
            <ActivityBadge key={category.id}>{category.name}</ActivityBadge>
          ))} */}

          {activity.positiveReviewPercentage ? (
            <ActivityBadge>
              <Star className="size-4 mr-1.5" />
              {convertToStars(activity.positiveReviewPercentage)}/5
            </ActivityBadge>
          ) : null}
        </div>
      </div>
    </Link>
  );
}

export function ActivityBadge({ ...props }: React.ComponentProps<'span'>) {
  return (
    <span
      {...props}
      className={cn(
        'flex items-center justify-center rounded-full border-2 px-3 py-1 font-medium capitalize text-subdued-foreground',
        textVariants({ size: 's' }),
        props.className,
      )}
    />
  );
}

export function ActivityPlaceholder(props: React.ComponentProps<'div'>) {
  return (
    <div
      {...props}
      className={cn(
        'flex h-full flex-col rounded-lg bg-card shadow-[0px_4px_0px_0px_rgba(25,_48,_53,_0.04)]',
        props.className,
      )}
    />
  );
}

export function ActivityPlaceholderImage(props: React.ComponentProps<typeof Skeleton>) {
  return (
    <Skeleton
      {...props}
      className={cn('h-[220px] w-full rounded-b-none rounded-t-lg', props.className)}
    />
  );
}

export function ActivityPlaceholderContent(props: React.ComponentProps<'div'>) {
  return <div {...props} className={cn('flex flex-col gap-y-4 p-4', props.className)} />;
}

export function ActivityPlaceholderTitle(props: React.ComponentProps<typeof H4>) {
  return <HeadingM {...props} />;
}

export function ActivityPlaceholderAction(props: React.ComponentProps<'div'>) {
  return (
    <div
      {...props}
      className={cn(
        buttonVariants({ variant: 'tertiary' }),
        'w-full shadow-[0px_4px_0px_0px_rgba(25,_48,_53,_0.04)]',
        props.className,
      )}
    />
  );
}
